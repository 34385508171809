import numeral from 'numeral';
const DecimalFormat = '0.00';
const MoneyFormat = '$0,0.00';

export default {
  methods: {
    formatDecimal(value) {
      return numeral(value).format(DecimalFormat);
    },
    formatMoney(value) {
      return numeral(value).format(MoneyFormat);
    },
  },
};
