<template>
  <div class="site-content container">
    <Loading v-if="isLoading" />
    <ErrorComponent v-if="hasError" :error="error" />
    <div class="row justify-content-center" v-if="!isLoading && !hasError">
      <div class="col-sm-8 col-md-6">
        <h3>Booking Number</h3>
        <p>
          <code>{{ bookingNumber }}</code>
        </p>
        <h3>Phone Number</h3>
        <p>
          <code>{{ phone }}</code>
        </p>
        <h3>Cart Total</h3>
        <p class="lead">
          <strong>{{ formatMoney(currentBookingTotal) }}</strong>
          <small class="text-black-50 ml-2" v-if="currentBookingIsPartial">
            Partial (Total is {{ formatMoney(currentBookingTotalToPay) }})
          </small>
        </p>

        <div class="alert alert-info" v-if="cardStatus">
          <font-awesome-icon icon="info-circle" />
          {{ cardStatus }}
        </div>

        <div class="alert alert-danger" v-if="cardError">
          <font-awesome-icon icon="exclamation-triangle" />
          {{ bookitError.message }}
          <ul v-if="bookitError.errorBag.length" class="mb-0">
            <li v-for="(error, i) in bookitError.errorBag" :key="`error-bag-${i}`">
              {{ error }}
            </li>
          </ul>
        </div>

        <div class="success-component" v-if="cardComplete">
          <font-awesome-icon icon="check-circle" />
          Transaction complete, you may close this window.
        </div>

        <div class="card" v-if="!cardComplete">
          <div class="card-body">
            <!-- CardToken placeholder -->
            <input type="hidden" name="CardToken" id="CardToken" />

            <div class="form-group">
              <label>Card Details</label>
              <div id="card-container"></div>
            </div>

            <div class="form-group">
              <button class="btn btn-success" :disabled="cardLoading" @click="submitPayment">
                Charge it!
                <font-awesome-icon v-if="cardLoading" icon="spinner" spin />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HasNumbers from '../mixins/HasNumbers';
import Loading from '@/components/Loading';
import ErrorComponent from '@/components/Error';

export default {
  name: 'Checkout',
  components: { ErrorComponent, Loading },
  mixins: [HasNumbers],
  data() {
    return {
      idempotencyKey: false,
      paymentForm: false,
      bookingNumber: false,
      phoneNumber: false,
      card: false,
      token: false,
      cardLoading: false,
      cardStatus: false,
      cardError: false,
      cardComplete: false,
      bookingError: {
        message: 'Missing or incorrect Booking data',
        errorBag: [],
      },
      bookitError: {
        message: 'Unknown BOOKIT error.',
        errorBag: [],
      },
      parameterError: {
        message: 'Missing or incorrect booking_data parameters',
        errorBag: [],
      },
    };
  },
  computed: {
    ...mapGetters('bookit', [
      'currentBooking',
      'currentBookingNumbers',
      'currentBookingTotal',
      'currentBookingIsPartial',
      'currentBookingTotalToPay',
    ]),
    ...mapGetters('ui', ['isLoading', 'hasError', 'error']),
    phone() {
      return this.phoneNumber
        ? this.phoneNumber.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        : '(---) --- ----';
    },
  },
  mounted() {
    this.initCheckout();
  },
  methods: {
    ...mapActions('bookit', [
      'hasBookitSession',
      'apiConfirmBooking',
      'apiExitBooking',
      'apiGetBooking',
      'apiLoadBooking',
      'apiProcessPayments',
      'apiSubmitPayment',
      'setCurrentBooking',
    ]),
    ...mapActions('ui', ['setLoading', 'setError']),
    initCheckout() {
      // Set the UI to display loading status
      this.setLoading(true);

      // Get the QueryParams
      this.getQueryParams();

      if (this.validateParams()) {
        // Initialize the BOOKIT session
        this.hasBookitSession()
          .then(() => {
            console.log('Checkout initCheckout() hasBookitSession then 1');

            return this.apiLoadBooking({ bookingNumber: this.bookingNumber });
          })
          .then(() => {
            console.log('Checkout initCheckout() hasBookitSession then 2');
            // #TODO Check for valid info
            // `PaymentStatus` should not be paid
            if (!this.validateBookingFromApi()) {
              let error = Error();
              error = Object.assign(error, this.bookingError);
              throw error;
            }
            // Load the Square Payment Form
            this.createSquarePaymentForm();
          })
          .catch((error) => {
            console.error('Checkout initCheckout() hasBookitSession catch()', error);
            this.setError(error);
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        this.setError(this.parameterError);
        this.setLoading(false);
      }
    },
    getQueryParams() {
      // get the query
      console.log('Checkout getQueryParams()', this.$route.query);
      // decode it
      const urlParams = this.$route.query.booking_data || '';
      const decoded = {};
      window
        .atob(urlParams)
        .split('&')
        .forEach((txt) => {
          decoded[txt.split('=')[0]] = txt.split('=')[1];
        });
      console.log('Checkout getQueryParams()', decoded);
      // get the bookingNumber from URL
      this.bookingNumber = decoded.BookingNumber || false;
      // get the phoneNumber from the URL
      this.phoneNumber = decoded.PhoneNumber || false;
    },
    validateParams() {
      // Check for each param
      let r = true;
      if (this.bookingNumber === false) {
        // #TODO Add additional checks to make sure the number is valid
        this.parameterError.errorBag.push('Missing BookingNumber');
        r = false;
      }
      if (this.phoneNumber === false) {
        // #TODO Add additional checks to make sure the number is valid
        this.parameterError.errorBag.push('Missing PhoneNumber');
        r = false;
      }

      return r;
    },
    validateBookingFromApi() {
      console.log('Checkout validateBookingFromApi()', this.currentBooking);
      // Valid payment statuses
      const validPaymentStatus = ['UNPAID', 'PARTLYPAID'];

      const bookings = this.currentBooking;
      const bookingNumbers = bookings.map((b) => b.BookingNumber).join(', ');
      // const hasErrors = bookings.filter((b) => b.HasError);
      const needsPayment = bookings.filter((b) => validPaymentStatus.includes(b.PaymentStatus.toUpperCase()));

      let r = true;

      // Ensure the BookingNumber we requested is in this batch
      if (
        bookings.filter((b) => Number(b.BookingNumber) === Number(this.bookingNumber)).length === 0
      ) {
        this.bookingError.errorBag.push(
          `BOOKIT returned a different BookingNumber than was expected. ` +
            `Sent: ${this.bookingNumber} vs Returned: ${bookingNumbers}`
        );
        r = false;
      }

      // Ensure at least one of the Bookings needs payment
      if (needsPayment.length === 0) {
        needsPayment.forEach((b) => {
          this.bookingError.errorBag.push(
            `This BookingNumber (${b.BookingNumber}) has already been paid. PaymentStatus: ${b.PaymentStatus}`
          );
        });
        r = false;
      }

      console.log('Checkout validateBookingFromApi() complete', r);
      return r;
    },

    payAndConfirm() {
      console.log('Cart payAndConfirm() started');
      this.cardStatus = 'Preparing Booking with BOOKIT...';

      // Mark the Booking as Incomplete
      this.apiConfirmBooking({ data: { bookingNumber: this.bookingNumber, finish_first: false } })
        .then((response) => {
          console.log('Cart payAndConfirm() apiConfirmBooking then', response);

          if (response.data.HasError === false) {
            // Add booking numbers to payment request
            let paymentForApi = {
              bookingNumbers: this.currentBookingNumbers,
              CardToken: this.token,
              IdempotencyKey: this.idempotencyKey,
              amount: this.formatDecimal(this.currentBookingTotal),
            };

            this.cardStatus = 'Processing credit card with Square...';
            // Submit payment to Square
            return this.apiSubmitPayment(paymentForApi).catch((error) => {
              console.error('Cart payAndConfirm() apiConfirmBooking apiSubmitPayment catch', error);
              // Reload the booking since it was confirmed prior to failed payment
              this.apiGetBooking({ returnMsg: false });

              // #TODO show error message
              this.cardStatus = false;
              this.cardLoading = false;
              this.cardError = true;
              this.bookitError = this.$errorProcessor(error);
            });
          } else {
            console.error('Cart payAndConfirm() apiConfirmBooking then HasError');
            // #TODO Return some sort of error here
            this.cardStatus = false;
            this.cardLoading = false;
            this.cardError = true;
            this.bookitError.message = 'There was an error marking Booking as incomplete.';
            this.bookitError.errorBag.push(`BOOKIT error: ${response.data.ErrorCode}`);
          }
        })
        .then((response) => {
          console.log('Cart payAndConfirm() apiConfirmBooking then 2', response);
          // Get the payment info. `payment` contains `amount_money`, `card_details`, `total_money` objects
          const { payment } = response.data;

          this.cardStatus = 'Sending payment information to BOOKIT...';

          return this.apiProcessPayments({
            bookings: this.currentBooking,
            card: {
              txnRef: payment.id,
              cardNumber: payment.card_details.card.last_4,
              dpsTxnRef: payment.id,
              receiptUrl: payment.receipt_url,
            },
            payment,
          });
        })
        .then((response) => {
          console.log('Cart payAndConfirm() apiConfirmBooking then 3', response);
          this.cardStatus = 'Wrapping up...';
          return this.apiExitBooking();
        })
        .then(() => {
          console.log('Cart payAndConfirm() apiConfirmBooking then 4 ALL DONE');
          this.cardStatus = false;
          this.cardLoading = false;
          this.cardComplete = true;
        })
        .catch((error) => {
          console.error('Cart payAndConfirm() apiConfirmBooking catch', error);

          this.cardStatus = false;
          this.cardLoading = false;
          this.cardError = true;
          this.bookitError = this.$errorProcessor(error);

          // Reload the booking since it was confirmed prior to failed payment
          this.apiGetBooking({ returnMsg: false });

          // #TODO show error message
        });
    },

    async submitPayment() {
      // Set the UI to display loading status
      this.cardLoading = true;
      this.cardStatus = 'Starting credit card processing...';

      // Set the IdempotencyKey since the cart has been finalized
      this.idempotencyKey = this.createIdempotencyKey();

      // Request a nonce from the SqPaymentForm object. This triggers the final processing
      await this.tokenize(this.card);
      this.payAndConfirm();
    },

    /**
     * Square Payment functions
     */
    /**
     * Create an idempotency key to ensure that duplicate payment requests are not made by accident
     */
    createIdempotencyKey() {
      // console.log('Checkout createIdempotencyKey()', this.idempotencyKey);
      if (this.idempotencyKey === false) {
        // console.log('Checkout createIdempotencyKey() is null', this.currentBookingNumbers);
        const string = this.currentBookingNumbers.join(';');

        let hash = 0;

        if (string.length === 0) return hash;

        for (let i = 0; i < string.length; i++) {
          let char = string.charCodeAt(i);
          hash = ((hash << 5) - hash) + char;
          hash = hash & hash;
        }

        this.idempotencyKey = `${hash}.${new Date().getTime() / 1000}`.replace('-', '');
      }
      // console.log('Checkout createIdempotencyKey()', this.idempotencyKey);

      return this.idempotencyKey;
    },

    async initializeCard(payments) {
      const darkModeCardStyle = {
        '.input-container': {
          borderColor: '#D9D8DB',
          borderRadius: '0px',
          borderWidth: '1px',
        },
        '.input-container.is-focus': {
          borderColor: '#F2C14C',
        },
        '.input-container.is-error': {
          borderColor: '#BE493C',
        },
        '.message-text': {
          color: '#514F58',
        },
        '.message-icon': {
          color: '#75727D',
        },
        '.message-text.is-error': {
          color: '#BE493C',
        },
        '.message-icon.is-error': {
          color: '#BE493C',
        },
        input: {
          backgroundColor: '#F7F7F7',
          color: '#514F58',
          fontFamily: 'helvetica neue, sans-serif',
        },
        'input.is-focus': {
          backgroundColor: '#FFFFFF',
        },
        'input::placeholder': {
          color: '#999999',
        },
        'input.is-error': {
          color: '#ff1600',
        },
      };

      const card = await payments.card({
        style: darkModeCardStyle,
      });
      await card.attach('#card-container');

      return card;
    },

    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        this.token = tokenResult.token;

        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    },

    /**
     * Create the Square PaymentForm and setup handlers
     */
    async createSquarePaymentForm() {
      console.log('Checkout createSquarePaymentForm()');
      if (!window.Square) {
        throw new Error('Square.js failed to load properly');
      }

      try {
        this.payments = window.Square.payments(
          process.env.VUE_APP_SQUARE_APPLICATION_ID,
          process.env.VUE_APP_SQUARE_LOCATION_ID
        );
      } catch ($e) {
        console.error('Cart createSquarePaymentForm() payments catch', $e);
        // show error
        return;
      }

      try {
        this.card = await this.initializeCard(this.payments);
      } catch (e) {
        console.error('Cart createSquarePaymentForm(): Initializing Card failed', e);
        return;
      }
    },
  },
};
</script>

<style></style>
