<template>
  <div class="col-sm-8 col-md-6 h-100 mx-auto d-flex justify-content-center align-items-center">
    <div class="loading-component">
      <div class="component-status">
        <h3>Loading...</h3>
        <font-awesome-icon icon="spinner" size="2x" spin />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
};
</script>

<style></style>
