<template>
  <div class="col-sm-8 col-md-6 h-100 mx-auto d-flex justify-content-center align-items-center">
    <div class="error-component">
      <div class="component-status">
        <h3>Error</h3>
        <font-awesome-icon icon="exclamation-triangle" size="2x" />
      </div>
      <div class="component-message">
        <h5>Message:</h5>
        <p>{{ error.message }}</p>
        <h5 v-if="errorBag.length">Errors:</h5>
        <ul v-if="errorBag.length" class="mb-0">
          <li v-for="(error, i) in errorBag" :key="`error-bag-${i}`">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: [Error, Boolean, Object],
      required: true,
    },
  },
  computed: {
    errorBag() {
      console.log('Error errorBag()', this.error);
      let errors = [];
      if (this.error.errorBag) {
        if (Array.isArray(this.error.errorBag)) {
          errors = this.error.errorBag;
        } else {
          errors = Object.keys(this.error.errorBag).flatMap((key) => this.error.errorBag[key]);
        }
      }

      // console.log('Error errorBag() errors', errors);
      return errors;
    },
  },
};
</script>

<style></style>
